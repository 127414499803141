// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-common-layout-components-loader-___styles__loader___pd2Ox{display:flex;flex-direction:column;align-items:center;padding:100px 20px;width:100%;background:#f7ffeb}.src-common-layout-components-loader-___styles__loaderInline___ZZUda{display:inline-flex}.src-common-layout-components-loader-___styles__large___PcAVa .ant-spin-dot{font-size:80px!important}.src-common-layout-components-loader-___styles__large___PcAVa .ant-spin-dot .ant-spin-dot-item{width:36px;height:36px}.src-common-layout-components-loader-___styles__noBackground___kS_Kq{background:none;box-shadow:none;padding:20px}.src-common-layout-components-loader-___styles__centerPosition___ROnI2{position:absolute;top:40%}\n", "",{"version":3,"sources":["webpack://./src/common/layout/components/loader/styles.sass"],"names":[],"mappings":"AAAA,+DAAQ,YAAA,CAAa,qBAAA,CAAsB,kBAAA,CAA3C,kBAAA,CAAkH,UAAA,CAAW,kBAAA,CAAmB,qEAAc,mBAAA,CAAoB,4EAA6B,wBAAA,CAA0B,+FAAgD,UAAA,CAAW,WAAA,CAAY,qEAAc,eAAA,CAAgB,eAAA,CAA9U,YAAA,CAA2W,uEAAgB,iBAAA,CAAkB,OAAA","sourcesContent":[".loader{display:flex;flex-direction:column;align-items:center;padding:20px;padding-top:100px;padding-bottom:100px;width:100%;background:#f7ffeb}.loaderInline{display:inline-flex}.large :global .ant-spin-dot{font-size:80px !important}.large :global .ant-spin-dot .ant-spin-dot-item{width:36px;height:36px}.noBackground{background:none;box-shadow:none;padding:20px}.centerPosition{position:absolute;top:40%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "src-common-layout-components-loader-___styles__loader___pd2Ox",
	"loaderInline": "src-common-layout-components-loader-___styles__loaderInline___ZZUda",
	"large": "src-common-layout-components-loader-___styles__large___PcAVa",
	"noBackground": "src-common-layout-components-loader-___styles__noBackground___kS_Kq",
	"centerPosition": "src-common-layout-components-loader-___styles__centerPosition___ROnI2"
};
export default ___CSS_LOADER_EXPORT___;
