// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-common-layout-components-card-___styles__titleSizeBig___yx8Mg .ant-card-head-title{font-size:24px}\n", "",{"version":3,"sources":["webpack://./src/common/layout/components/card/styles.sass"],"names":[],"mappings":"AAAA,wFAA4C,cAAA","sourcesContent":[".titleSizeBig :global(.ant-card-head-title){font-size:24px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleSizeBig": "src-common-layout-components-card-___styles__titleSizeBig___yx8Mg"
};
export default ___CSS_LOADER_EXPORT___;
