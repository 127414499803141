export const commonTranslations = {
    CS: {
        common: {
            title: 'ČESKOMORAVSKÁ SPOLEČNOST CHOVATELŮ',
            logout: 'Odhlásit se',
            company: 'ČMSCH, a.s.',
            onFacebook: 'na facebooku',
            waitForLogin: 'Prosím, vyčkejte, budete přesměrování na přihlášení.',
            unknownNetworkProblem: 'Neznámý problém.',
            unknownProblemTryAgain: 'Neznámá chyba. Zkuste to prosím znovu.',
            noAnswerReceived: 'Nepřišla žádná odpověď.',
            loginText1: 'Přihlašte se ',
            loginText2: 'prosím znovu.',
            month1: 'Leden',
            month2: 'Únor',
            month3: 'Březen',
            month4: 'Duben',
            month5: 'Květen',
            month6: 'Červen',
            month7: 'Červenec',
            month8: 'Srpen',
            month9: 'Září',
            month10: 'Říjen',
            month11: 'Listopad',
            month12: 'Prosinec',
            yes: 'Ano',
            no: 'Ne',
            unselected: 'Nevybráno',
            notFound: 'Nenalezeno.',
            confirmTitle: 'Potvrzení',
            currency: {
                czk: 'Kč',
            },
            sex: {
                female: 'Kráva',
                male: 'Býk',
            },
            parenthood: {
                missing: 'Chybí',
                matches: 'Shoduje se',
                mismatches: 'Neshoduje se',
            },
            correctOrigin: {
                igenetika: 'iGenetika',
                eskot: 'eSkot',
            },
            address: {
                street: 'Ulice',
                city: 'Město',
                zip: 'Směrovací číslo',
                address: 'Adresa',
                billingAddress: 'Fakturační adresa',
            },
            create: 'Vytvořit',
            edit: 'Editovat',
            cancel: 'Zrušit',
            search: 'Vyhledat',
            delete: 'Odstranit',
            addAnother: 'Přidat další',
            add: 'Přidat',
            remove: 'Odebrat',
            editType: 'Typ úpravy',
            earTag: 'Ušní známka',
            earTagPlaceholder: 'např. CZ000123456789',
            dateOfBirth: 'Datum narození',
            dateOfBirthPlaceholder: '18.01.2015',
            resetButton: 'Resetovat',
            confirmAction: 'Opravdu chcete provést tuto akci?',
            confirm: 'Potvrdit',
            noData: 'Nic nenalezeno',
            noDataSearchable: 'Napište alespoň 3 znaky',
            noLaboratoryNumber: 'Objednávka musí mít laboratorní číslo.',
            form: {
                pristine: 'Data jsou beze změny.',
                submitting: 'Formulář se odesílá.',
                asyncValidating: 'Formulář se validuje.',
                notValid: 'Data ve formuláři nejsou validní.',
            },
            registryPlaceholder: 'např. NEO-996',
            validator: {
                isRequired: '{{label}} je povinný údaj.',
                oneChar: 'znak',
                lessThanFiveChars: 'znaky',
                moreChars: 'znaků',
                minLength: '{{label}} musí mít délku alespoň {{minLength}} {{characters}}.',
                maxLength: '{{label}} musí mít délku nejvýše {{maxLength}} {{characters}}.',
                mustHaveFormat: '{{label}} má nesprávný formát.',
                arrayMustHaveFormat: 'Jeden nebo více prvků pro pole {{lable}} má nesprávný formát',
                minNumber: '{{label}} musí být minimálně {{min}}.',
                maxNumber: '{{label}} musí být maximálně {{max}}.',
                cantBeNegativeNumber: '{{label}} nemůže být záporné číslo.',
                cinNotValid: '{{label}} nemá správný formát. Je nutné zadat celé {{label}} včetně úvodních nul.',
                cinUserDetails: 'Musí být vyplněno {{cinLabel}} nebo zaškrtnuto {{noCinLabel}}.',
                cinBulkEdit: 'Musí být vyplněno {{cinLabel}} nebo {{prefillNameLabel}}.',
                registryNotValid: '{{label}} nemá platný formát. Akceptovaný formát je např.: NXB-667, 280-503, ME-184 nebo 266-009.',
                mustBeInteger: '{{label}} musí být celé číslo.',
                mustBeDecimal: '{{label}} musí být desetinné číslo.',
                decimalOneDecimalPlace: '{{label}} musí být číslo, max. 1 desetinné místo.',
                arrayWithNoEmptyValue: '{{label}} nesmí obsahovat prázdnou hodnotu.',
                valueExists: 'Hodnota pro {{label}} už existuje.',
            },
            pageNotFound: 'Stránka nenalezena.',
            goToHomepage: 'Pokračovat na úvodní stránku.',
            loading: 'Načítání…',
            note: 'Poznámka',
            email: 'E-mail',
            unexpectedError: 'Neočekávaná chyba',
            unexpectedErrorOccured: 'V aplikaci došlo k neočekávané chybě.',
            problemLogged: 'Problém byl zaznamenán do našeho systému a budeme se ho snažit co nejdříve vyřešit.',
            sorryForIncovenience: 'Omlouváme se za způsobené potíže.',
            restartApp: 'Restartovat aplikaci',
            errorInApp: 'V aplikaci nastala chyba.',
            errorOccured: 'Nastala chyba',
            success: 'Úspěch',
        },
    },
    EN: {
        common: {
            title: 'Czech Moravian Breeders’ Corporation, Inc.',
            logout: 'Logout',
            company: 'CMBC, Inc.',
            onFacebook: 'On Facebook;',
            waitForLogin: 'Please wait while you’re redirected to login.',
            unknownNetworkProblem: 'Unknown problem',
            unknownProblemTryAgain: 'Unknown error. Please try again.',
            noAnswerReceived: 'No answer received.',
            loginText1: 'Please login',
            loginText2: 'Please login again.',
            month1: 'January',
            month2: 'February',
            month3: 'March',
            month4: 'April',
            month5: 'May',
            month6: 'June',
            month7: 'July',
            month8: 'August',
            month9: 'September',
            month10: 'October',
            month11: 'November',
            month12: 'December',
            yes: 'Yes',
            no: 'No',
            unselected: 'Unselected',
            notFound: 'Not found.',
            confirmTitle: 'Confirmation',
            currency: {
                czk: 'Currency: Czech crowns',
            },
            sex: {
                female: 'Cow',
                male: 'Bull',
            },
            parenthood: {
                missing: 'Missing',
                matches: 'Matches',
                mismatches: 'Mismatches',
            },
            correctOrigin: {
                igenetika: 'iGenetika',
                eskot: 'eSkot',
            },
            address: {
                street: 'Street',
                city: 'City',
                zip: 'Postal code',
                address: 'Address',
                billingAddress: 'Billing address',
            },
            create: 'Create',
            edit: 'Edit',
            cancel: 'Cancel',
            search: 'Search',
            delete: 'Delete',
            addAnother: 'Add another',
            add: 'Add',
            remove: 'Remove',
            editType: 'Edit type',
            earTag: 'Ear tag',
            earTagPlaceholder: 'e.g. CZ000123456789',
            dateOfBirth: 'Date of birth',
            dateOfBirthPlaceholder: '18.01.2015',
            resetButton: 'Reset',
            confirmAction: 'Are you sure you want to perform this action?',
            confirm: 'Confirm',
            noData: 'No data',
            noDataSearchable: 'Type at least 3 characters',
            noLaboratoryNumber: 'Order has to have laboratory number.',
            form: {
                notValid: 'Form data is not valid.',
                pristine: 'Data is uchanged.',
                submitting: 'Form is being submitted.',
                asyncValidating: 'Form is being validated.',
            },
            registryPlaceholder: 'e.g. NEO-996',
            validator: {
                isRequired: '{{label}} is required.',
                oneChar: 'character',
                lessThanFiveChars: 'characters',
                moreChars: 'characters',
                minLength: '{{label}} must have length at least {{minLength}} {{characters}}.',
                maxLength: '{{label}} must have length at most {{maxLength}} {{characters}}.',
                mustHaveFormat: '{{label}} has invalid format.',
                arrayMustHaveFormat: 'One or more elements for the array {{lable}} have invalid format.',
                minNumber: '{{label}} must be at least {{min}}.',
                maxNumber: '{{label}} must be at most {{max}}.',
                cantBeNegativeNumber: '{{label}} can\'t be a negative number.',
                cinNotValid: '{{label}} has invalid format. You have to fill in whole {{label}} including leading zeros.',
                cinUserDetails: '{{cinLabel}} must be filled out or {{noCinLabel}} checked out.',
                cinBulkEdit: '{{cinLabel}} or {{prefillNameLabel}} must be checked.',
                registryNotValid: '{{label}} has invalid format. Accepted formats are e.g.: NXB-667, 280-503, ME-184 or 266-009.',
                mustBeInteger: '{{label}} must be integer.',
                mustBeDecimal: '{{label}} must be decimal.',
                decimalOneDecimalPlace: '{{label}} must be number, max. 1 decimal place.',
                arrayWithNoEmptyValue: '{{label}} must not contain empty value.',
                valueExists: 'Value for {{label}} already exists.',
            },
            pageNotFound: 'Page not found.',
            goToHomepage: 'Continue to homepage.',
            loading: 'Loading…',
            note: 'Note',
            email: 'E-mail',
            unexpectedError: 'Unexpected error',
            unexpectedErrorOccured: 'An unexpected error occurred in the application.',
            problemLogged: 'The problem has been logged in our system and we will try to solve it as soon as possible.',
            sorryForIncovenience: 'We apologize for the inconvenience.',
            restartApp: 'Restart the app',
            errorInApp: 'Error occurred in the application.',
            errorOccured: 'Error occurred',
            success: 'Success',
        },
    },
};
