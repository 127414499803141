import {Button, ConfirmButton, Visuals, IconName, LinkButton} from '@fl/cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';

type ActionButtonType = 'default' | 'delete' | 'goBack' | 'cancel';

const getIcon = (type?: ActionButtonType): IconName | undefined => {
    switch (type) {
        case 'delete': return 'deleteOutlined';
        case 'goBack': return 'rollbackOutlined';
        case 'cancel': return 'closeOutlined';
        default: return;
    }
};

const getVisuals = (type?: ActionButtonType): Visuals => {
    switch (type) {
        case 'goBack': return 'default';
        case 'cancel': return 'default';
        default: return 'primary';
    }
};

interface Props {
    children: ReactNode;
    type?: ActionButtonType;
    icon?: IconName;
    disabled?: boolean;
    disabledTooltip?: string;
    tooltip?: string;
    testId?: string;
    loading?: boolean;
    linkTo?: string;
    onClick?(): void;
}

const ActionButtonBase: FC<Props> = props => {
    const {
        children,
        type,
        icon,
        disabled,
        disabledTooltip,
        tooltip,
        onClick,
        testId,
        loading,
        linkTo,
    } = props;

    if (type === 'delete') {
        return (
            <ConfirmButton
                visuals={getVisuals(type)}
                danger
                icon={icon ?? getIcon(type)}
                onClick={onClick}
                disabled={disabled}
                tooltip={disabled ? disabledTooltip : tooltip}
                block
                bottomMargin
                testId={testId}
                loading={loading}
            >
                {children}
            </ConfirmButton>
        );
    }

    if (linkTo) {
        return (
            <LinkButton
                to={linkTo}
                visuals={getVisuals(type)}
                icon={icon ?? getIcon(type)}
                onClick={onClick}
                disabled={disabled}
                tooltip={disabled ? disabledTooltip : tooltip}
                block
                bottomMargin
                testId={testId}
            >
                {children}
            </LinkButton>
        );
    }

    return (
        <Button
            visuals={getVisuals(type)}
            icon={icon ?? getIcon(type)}
            onClick={onClick}
            disabled={disabled}
            tooltip={disabled ? disabledTooltip : tooltip}
            block
            bottomMargin
            testId={testId}
            loading={loading}
        >
            {children}
        </Button>
    );
};

export const ActionButton = memo(ActionButtonBase);
