// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-common-layout-components-divider-___styles__dividerHorizontal___xSU3H.src-common-layout-components-divider-___styles__marginSmallest___VOhMK{margin:8px 0!important}.src-common-layout-components-divider-___styles__dividerHorizontal___xSU3H.src-common-layout-components-divider-___styles__marginSmall___QMSUk{margin:16px 0!important}\n", "",{"version":3,"sources":["webpack://./src/common/layout/components/divider/styles.sass"],"names":[],"mappings":"AAAA,kJAAA,sBAAA,CAA0D,+IAA1D,uBAAA","sourcesContent":[".dividerHorizontal.marginSmallest{margin:8px 0 !important}.dividerHorizontal.marginSmall{margin:16px 0 !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dividerHorizontal": "src-common-layout-components-divider-___styles__dividerHorizontal___xSU3H",
	"marginSmallest": "src-common-layout-components-divider-___styles__marginSmallest___VOhMK",
	"marginSmall": "src-common-layout-components-divider-___styles__marginSmall___QMSUk"
};
export default ___CSS_LOADER_EXPORT___;
