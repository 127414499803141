// eslint-disable-next-line @fl/relative-imports-in-index-files-only,no-restricted-imports
import * as Antd from 'antd';

export const Ant = Antd;
export const AntAlert = Antd.Alert;
export const AntDropdown = Antd.Dropdown;
export const AntLayout = Antd.Layout;
export const AntMenu = Antd.Menu;
export const AntSpin = Antd.Spin;
export const {Tabs} = Antd;
