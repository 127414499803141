import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';

import {Ant} from 'common/ant';

import styles from './styles.sass';

interface Props {
    children?: ReactNode;
    type?: 'horizontal' | 'vertical';
    orientation?: 'left' | 'right' | 'center';
    dashed?: boolean;
    plain?: boolean;
    marginSize?: 'smallest' | 'small';
}

const DividerBase: FC<Props> = props => {
    const {
        children,
        type = 'horizontal',
        orientation,
        dashed,
        plain,
        marginSize,
    } = props;

    const className = classNames(
        type === 'horizontal' && styles.dividerHorizontal,
        marginSize === 'small' && styles.marginSmall,
        marginSize === 'smallest' && styles.marginSmallest,
    );

    return (
        <Ant.Divider
            type={type}
            orientation={orientation}
            dashed={dashed}
            plain={plain}
            className={className}
        >
            {children}
        </Ant.Divider>
    );
};

export const Divider = memo(DividerBase);
