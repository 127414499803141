import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo, PropsWithChildren} from 'react';

import {AntSpin} from 'common/ant';
import {useOurTranslation} from 'translations';

import styles from './styles.sass';

export type LoaderSize = 'large';

export interface LoaderProps {
    show?: boolean;
    size?: LoaderSize;
    text?: string;
    inline?: boolean;
    noBackground?: boolean;
    testId?: string;
    centerPosition?: boolean;
    inLoaderWrapper?: boolean;
}

const LoaderBase: FC<PropsWithChildren<LoaderProps>> = props => {
    const {
        show,
        noBackground,
        testId = 'loader',
        inline,
        size = 'large',
        text,
        centerPosition,
        children,
        inLoaderWrapper,
    } = props;

    const {t} = useOurTranslation('common');

    const className = classNames(
        !children && styles.loader,
        inline && styles.loaderInline,
        !inLoaderWrapper && styles[size],
        noBackground && styles.noBackground,
        centerPosition && styles.centerPosition,
    );

    return (
        <div
            className={className}
            data-test-id={testId}
        >
            {show
                ? (
                    <AntSpin
                        size={size}
                        tip={text ?? t('loading')}
                    >
                        {children}
                    </AntSpin>
                )
                : children}
        </div>
    );
};

export const Loader = memo(LoaderBase);
