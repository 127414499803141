import React, {memo, FC, ReactNode} from 'react';

import {Ant} from 'common/ant';

interface Props {
    title: string;
    visible: boolean;
    footer: ReactNode;
    children: ReactNode;
    dontDestroyOnClose?: boolean;
    width?: number;
    onCancel(): void;
}

const ModalBase: FC<Props> = props => {
    const {title, visible, footer, children, dontDestroyOnClose, onCancel, width} = props;

    return (
        <Ant.Modal
            title={title}
            visible={visible}
            onCancel={onCancel}
            footer={footer}
            destroyOnClose={!dontDestroyOnClose}
            maskClosable={false}
            keyboard={false}
            width={width}
        >
            {children}
        </Ant.Modal>
    );
};

export const Modal = memo(ModalBase);
