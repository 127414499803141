import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';

import {Ant} from 'common/ant';

import styles from './styles.sass';

interface Props {
    children: ReactNode;
    title?: ReactNode;
    fullWidth?: boolean;
    titleSize?: 'big';
    noPadding?: boolean;
    extra?: ReactNode;
}

const CardBase: FC<Props> = props => {
    const {title, children, fullWidth, titleSize, noPadding, extra} = props;

    const className = classNames(
        fullWidth ? 'w-100' : 'w-auto',
        titleSize === 'big' && styles.titleSizeBig,
    );

    const bodyStyle = noPadding ? {padding: 0} : undefined;

    return (
        <Ant.Card
            title={title}
            className={className}
            bodyStyle={bodyStyle}
            extra={extra}
        >
            {children}
        </Ant.Card>
    );
};

export const Card = memo(CardBase);
