import {Button} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {Divider} from 'common/layout';
import {TFunction, useOurTranslation} from 'translations';

interface Props {
    editing?: boolean;
    disabledSubmit?: boolean;
    disabledCancel?: boolean;
    submitTitle?: string;
    cancelTitle?: string;
    submitTooltip?: string;
    cancelTooltip?: string;
    withoutCancel?: boolean;
    valid?: boolean;
    submitting?: boolean;
    asyncValidating?: boolean;
    pristine?: boolean;
    submitCanBeDisabled?: boolean;
    onCancel?(): void;
}

const getSubmitButtonTooltip = (
    t: TFunction<'common'>,
    valid?: boolean,
    submitting?: boolean,
    asyncValidating?: boolean,
    pristine?: boolean,
): string | undefined => {
    if (pristine) {
        return t('form.pristine');
    } else if (submitting) {
        return t('form.submitting');
    } else if (asyncValidating) {
        return t('form.asyncValidating');
    } else if (valid === false) {
        return t('form.notValid');
    } else {
        return undefined;
    }
};

const getCancelButtonTooltip = (
    t: TFunction<'common'>,
    submitting?: boolean,
    pristine?: boolean,
): string | undefined => {
    if (submitting) {
        return t('form.submitting');
    } else if (pristine) {
        return t('form.pristine');
    } else {
        return undefined;
    }
};

const FooterButtonsBase: FC<Props> = props => {
    const {
        editing,
        disabledSubmit,
        disabledCancel,
        submitTitle,
        cancelTitle,
        withoutCancel,
        submitting,
        submitTooltip,
        cancelTooltip,
        asyncValidating,
        valid,
        pristine,
        submitCanBeDisabled,
        onCancel,
    } = props;

    const {t} = useOurTranslation('common');
    const isDisabled = disabledSubmit || submitting || asyncValidating || submitCanBeDisabled && (
        valid === false || pristine
    );
    const title = submitTooltip || getSubmitButtonTooltip(
        t,
        valid,
        submitting,
        asyncValidating,
        pristine,
    );

    return (
        <Fragment>
            <Divider />
            <div className="row">
                <div
                    className="col-12 d-flex justify-content-end"
                    data-test-id="reset-button"
                >
                    {!withoutCancel && (
                        <div
                            className="mr-2"
                            data-test-id="cancel-button"
                        >
                            <Button
                                key="back"
                                type="reset"
                                onClick={onCancel}
                                disabled={disabledCancel || submitting || pristine}
                                tooltip={cancelTooltip || getCancelButtonTooltip(t, submitting, pristine)}
                            >
                                {cancelTitle || t('cancel')}
                            </Button>
                        </div>
                    )}
                    <div
                        className="mr-2"
                        data-test-id="submit-button"
                    >
                        <Button
                            key="submit"
                            visuals="primary"
                            type="submit"
                            disabled={isDisabled}
                            tooltip={title}
                        >
                            {submitTitle || t(editing ? 'edit' : 'create')}
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export const FooterButtons = memo(FooterButtonsBase);
