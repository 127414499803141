import Icon, {
    ManOutlined,
    WomanOutlined,
    MailOutlined,
    SettingFilled,
    FileSearchOutlined,
    SwitcherFilled,
    AreaChartOutlined,
    AuditOutlined,
    ClusterOutlined,
    ToolFilled,
    InfoCircleOutlined,
    GlobalOutlined,
    LogoutOutlined,
    FilterFilled,
    SearchOutlined,
    ReadOutlined,
    TagsOutlined,
    UnorderedListOutlined,
    OrderedListOutlined,
    DatabaseOutlined,
    FileDoneOutlined,
    TranslationOutlined,
    SwapOutlined,
    SlidersOutlined,
    CaretLeftOutlined,
    CaretRightOutlined,
    CaretUpOutlined,
    CaretDownOutlined,
    DeleteOutlined,
    CloseCircleOutlined,
    EditOutlined,
    CheckCircleOutlined,
    MoreOutlined,
    CloseSquareFilled,
    LockOutlined,
    UnlockOutlined,
    LockFilled,
    UnlockFilled,
    EyeOutlined,
    EyeInvisibleOutlined,
    EyeFilled,
    EyeInvisibleFilled,
    SaveOutlined,
    SaveFilled,
    DeleteFilled,
    TableOutlined,
    CloseSquareOutlined,
    PlusOutlined,
    PlusSquareFilled,
    ZoomInOutlined,
    CheckSquareFilled,
    RollbackOutlined,
    CheckOutlined,
    BranchesOutlined,
    FileTextOutlined,
    StopOutlined,
    ExportOutlined,
    PoweroffOutlined,
    DownloadOutlined,
    ApartmentOutlined,
    RetweetOutlined,
    FileOutlined,
    FileExcelOutlined,
    UpOutlined,
    DownOutlined,
    CloseOutlined,
    FilterOutlined,
    CheckCircleFilled,
    MailFilled,
    LoadingOutlined,
    WarningFilled,
    QuestionCircleOutlined,
    FacebookFilled,
} from '@ant-design/icons/';
import React, {ReactNode} from 'react';

import {
    animalSearchIcon,
    bullIcon,
    cabinetIcon,
    cowIcon,
    keyIcon,
    peopleIcon,
    roleIcon,
    techniciansIcon,
    userIcon,
    companyIcon,
    clipboardIcon,
    bullLineIcon,
    booksIcon,
    dataIcon,
    animalListIcon,
    changeNumbers,
    fileE,
} from 'common/icons/icons-jsx';

export type IconName =
    | 'manOutlined'
    | 'womanOutlined'
    | 'mailOutlined'
    | 'settingFilled'
    | 'gearIcon'
    | 'cowIcon'
    | 'bullIcon'
    | 'animalSearchIcon'
    | 'fileSearchOutlined'
    | 'switcherFilled'
    | 'peopleIcon'
    | 'keyIcon'
    | 'roleIcon'
    | 'clusterOutlined'
    | 'auditOutlined'
    | 'areaChartOutlined'
    | 'infoCircleOutlined'
    | 'globalOutlined'
    | 'logOutOutlined'
    | 'userIcon'
    | 'toolFilled'
    | 'filterFilled'
    | 'searchOutlined'
    | 'cabinetIcon'
    | 'readOutlined'
    | 'techniciansIcon'
    | 'tagsOutlined'
    | 'companyIcon'
    | 'clipboardIcon'
    | 'unorderedListOutlined'
    | 'orderedListOutlined'
    | 'databaseOutlined'
    | 'bullLineIcon'
    | 'booksIcon'
    | 'fileDoneOutlined'
    | 'dataIcon'
    | 'animalListIcon'
    | 'translationOutlined'
    | 'changeNumbers'
    | 'fileE'
    | 'swapOutlined'
    | 'slidersOutlined'
    | 'caretLeftOutlined'
    | 'caretRightOutlined'
    | 'caretUpOutlined'
    | 'caretDownOutlined'
    | 'deleteOutlined'
    | 'closeCircleOutlined'
    | 'editOutlined'
    | 'checkCircleOutlined'
    | 'moreOutlined'
    | 'closeSquareFilled'
    | 'lockOutlined'
    | 'unlockOutlined'
    | 'lockFilled'
    | 'unlockFilled'
    | 'eyeOutlined'
    | 'eyeInvisibleOutlined'
    | 'eyeFilled'
    | 'eyeInvisibleFilled'
    | 'saveOutlined'
    | 'saveFilled'
    | 'deleteFilled'
    | 'tableOutlined'
    | 'closeSquareOutlined'
    | 'plusOutlined'
    | 'plusSquareFilled'
    | 'zoomInOutlined'
    | 'checkSquareFilled'
    | 'rollbackOutlined'
    | 'checkOutlined'
    | 'branchesOutlined'
    | 'fileTextOutlined'
    | 'stopOutlined'
    | 'exportOutlined'
    | 'powerOffOutlined'
    | 'downloadOutlined'
    | 'apartmentOutlined'
    | 'retweetOutlined'
    | 'fileOutlined'
    | 'fileExcelOutlined'
    | 'upOutlined'
    | 'downOutlined'
    | 'closeOutlined'
    | 'filterOutlined'
    | 'checkCircleFilled'
    | 'mailFilled'
    | 'loadingOutlined'
    | 'warningFilled'
    | 'questionCircleOutlined'
    | 'facebookFilled'
;

export const ICONS: Readonly<Record<IconName, ReactNode>> = {
    manOutlined: <ManOutlined />,
    womanOutlined: <WomanOutlined />,
    mailOutlined: <MailOutlined />,
    settingFilled: <SettingFilled />,
    gearIcon: <Icon component={cowIcon} />,
    cowIcon: <Icon component={cowIcon} />,
    bullIcon: <Icon component={bullIcon} />,
    animalSearchIcon: <Icon component={animalSearchIcon} />,
    fileSearchOutlined: <FileSearchOutlined />,
    switcherFilled: <SwitcherFilled />,
    peopleIcon: <Icon component={peopleIcon} />,
    roleIcon: <Icon component={roleIcon} />,
    keyIcon: <Icon component={keyIcon} />,
    areaChartOutlined: <AreaChartOutlined />,
    auditOutlined: <AuditOutlined />,
    clusterOutlined: <ClusterOutlined />,
    infoCircleOutlined: <InfoCircleOutlined />,
    globalOutlined: <GlobalOutlined />,
    logOutOutlined: <LogoutOutlined />,
    userIcon: <Icon component={userIcon} />,
    toolFilled: <ToolFilled />,
    cabinetIcon: <Icon component={cabinetIcon} />,
    filterFilled: <FilterFilled />,
    searchOutlined: <SearchOutlined />,
    readOutlined: <ReadOutlined />,
    techniciansIcon: <Icon component={techniciansIcon} />,
    tagsOutlined: <TagsOutlined />,
    companyIcon: <Icon component={companyIcon} />,
    clipboardIcon: <Icon component={clipboardIcon} />,
    unorderedListOutlined: <UnorderedListOutlined />,
    orderedListOutlined: <OrderedListOutlined />,
    databaseOutlined: <DatabaseOutlined />,
    bullLineIcon: <Icon component={bullLineIcon} />,
    booksIcon: <Icon component={booksIcon} />,
    fileDoneOutlined: <FileDoneOutlined />,
    dataIcon: <Icon component={dataIcon} />,
    animalListIcon: <Icon component={animalListIcon} />,
    translationOutlined: <TranslationOutlined />,
    changeNumbers: <Icon component={changeNumbers} />,
    fileE: <Icon component={fileE} />,
    swapOutlined: <SwapOutlined />,
    slidersOutlined: <SlidersOutlined />,
    caretLeftOutlined: <CaretLeftOutlined />,
    caretRightOutlined: <CaretRightOutlined />,
    caretUpOutlined: <CaretUpOutlined />,
    caretDownOutlined: <CaretDownOutlined />,
    deleteOutlined: <DeleteOutlined />,
    closeCircleOutlined: <CloseCircleOutlined />,
    editOutlined: <EditOutlined />,
    checkCircleOutlined: <CheckCircleOutlined />,
    moreOutlined: <MoreOutlined />,
    closeSquareFilled: <CloseSquareFilled />,
    lockOutlined: <LockOutlined />,
    unlockOutlined: <UnlockOutlined />,
    lockFilled: <LockFilled />,
    unlockFilled: <UnlockFilled />,
    eyeOutlined: <EyeOutlined />,
    eyeInvisibleOutlined: <EyeInvisibleOutlined />,
    eyeFilled: <EyeFilled />,
    eyeInvisibleFilled: <EyeInvisibleFilled />,
    saveOutlined: <SaveOutlined />,
    saveFilled: <SaveFilled />,
    deleteFilled: <DeleteFilled />,
    tableOutlined: <TableOutlined />,
    closeSquareOutlined: <CloseSquareOutlined />,
    plusOutlined: <PlusOutlined />,
    plusSquareFilled: <PlusSquareFilled />,
    zoomInOutlined: <ZoomInOutlined />,
    checkSquareFilled: <CheckSquareFilled />,
    rollbackOutlined: <RollbackOutlined />,
    checkOutlined: <CheckOutlined />,
    branchesOutlined: <BranchesOutlined />,
    fileTextOutlined: <FileTextOutlined />,
    stopOutlined: <StopOutlined />,
    exportOutlined: <ExportOutlined />,
    powerOffOutlined: <PoweroffOutlined />,
    downloadOutlined: <DownloadOutlined />,
    apartmentOutlined: <ApartmentOutlined />,
    retweetOutlined: <RetweetOutlined />,
    fileOutlined: <FileOutlined />,
    fileExcelOutlined: <FileExcelOutlined />,
    upOutlined: <UpOutlined />,
    downOutlined: <DownOutlined />,
    closeOutlined: <CloseOutlined />,
    filterOutlined: <FilterOutlined />,
    checkCircleFilled: <CheckCircleFilled />,
    mailFilled: <MailFilled />,
    loadingOutlined: <LoadingOutlined />,
    warningFilled: <WarningFilled />,
    questionCircleOutlined: <QuestionCircleOutlined />,
    facebookFilled: <FacebookFilled />,
};
